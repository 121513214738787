/* globals CSS */
import $ from 'jquery'
import Swiper from 'swiper'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import 'swiper/dist/css/swiper.min.css'
import * as basicScroll from 'basicscroll'

class SliderImageTextModal extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.modalInitialised = false
    this.slideToSpeed = 50
    this.sliderSpeed = 600
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  bindFunctions () {
    this.expandModal = this.expandModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-slider-modal-expand]', this.expandModal)
    this.$.on('click', '[data-slider-modal-close]', this.closeModal)
  }

  resolveElements () {
    this.$sliderImage = $('[data-slider-image]', this)
    this.$sliderCaption = $('[data-slider-caption]', this)
    this.$sliderModal = $('[data-slider-modal]', this)
    this.$sliderModalCaption = $('[data-slider-modal-caption]', this)
    this.$modal = $('.slider-modal', this)
    this.$modalScroll = $('.slider-modal-inner', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$sliderPagination = $('[data-slider-pagination]', this)
    this.$buttonModalNext = $('[data-slider-modal-button="next"]', this)
    this.$buttonModalPrev = $('[data-slider-modal-button="prev"]', this)
    this.$sliderModalPagination = $('[data-slider-modal-pagination]', this)
    this.$body = $('html, body')
  }

  connectedCallback () {
    this.initSlider()
    if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
      this.$parallax = basicScroll.create({
        elem: $('.parallax', this)[0],
        from: 'top-bottom',
        to: 'bottom-top',
        direct: this.$[0],
        props: {
          '--slideDown': {
            from: '-20%',
            to: '20%'
          }
        }
      })
      this.$parallax.start()
    }
  }

  initSlider () {
    const { options } = this.props
    const configImage = {
      navigation: {
        nextEl: this.$buttonNext,
        prevEl: this.$buttonPrev
      },
      pagination: {
        el: this.$sliderPagination,
        type: 'fraction'
      },
      speed: this.sliderSpeed,
      loop: true,
      a11y: options.a11y
    }

    const configCaption = {
      loop: true,
      speed: this.sliderSpeed,
      a11y: options.a11y
    }

    if (options.autoplay && options.autoplaySpeed) {
      configImage['autoplay'] = {
        delay: options.autoplaySpeed
      }
    }
    if (this.hasMultipleSlides()) {
      this.sliderImage = new Swiper(this.$sliderImage, configImage)
      this.sliderCaption = new Swiper(this.$sliderCaption, configCaption)

      this.sliderImage.controller.control = this.sliderCaption
      this.sliderCaption.controller.control = this.sliderImage
    }
  }

  expandModal () {
    const configModal = {
      navigation: {
        nextEl: this.$buttonModalNext,
        prevEl: this.$buttonModalPrev
      },
      pagination: {
        el: this.$sliderModalPagination,
        type: 'fraction'
      },
      loop: true,
      speed: this.sliderSpeed,
      a11y: this.props.a11y
    }
    const configModalCaption = {
      loop: true,
      speed: this.sliderSpeed,
      a11y: this.props.a11y
    }
    disableBodyScroll(this.$modalScroll.get(0))
    this.$modal.fadeIn({
      start: function () {
        $(this).css({
          display: 'flex'
        })
      }
    })

    if (this.modalInitialised === false && this.hasMultipleSlides()) {
      this.sliderModal = new Swiper(this.$sliderModal, configModal)
      this.sliderModalCaption = new Swiper(this.$sliderModalCaption, configModalCaption)

      this.sliderModal.controller.control = this.sliderModalCaption
      this.sliderModalCaption.controller.control = this.sliderModal

      this.sliderModal.slideTo(this.sliderImage.activeIndex, this.slideToSpeed, false)
      this.modalInitialised = true
    } else if (this.modalInitialised && this.hasMultipleSlides()) {
      this.sliderModal.update()
      this.sliderModalCaption.update()
      this.sliderModal.slideTo(this.sliderImage.activeIndex, this.slideToSpeed, false)
    }
  }

  closeModal () {
    enableBodyScroll(this.$modalScroll.get(0))
    this.$modal.fadeOut(300)
    if (this.hasMultipleSlides()) {
      this.sliderImage.slideTo(this.sliderModal.activeIndex, this.slideToSpeed, false)
    }
  }

  hasMultipleSlides () {
    return this.$sliderImage.find('.swiper-slide').length > 1
  }
}

window.customElements.define('flynt-slider-image-text-modal', SliderImageTextModal, { extends: 'div' })
