import $ from 'jquery'

class AccordionVertical extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.animationDuration = 300
  }

  bindFunctions () {
    this.togglePanel = this.togglePanel.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
  }

  connectedCallback () {
    this.$.on('click', '[aria-controls]', this.togglePanel)

    if (window.location.hash) {
      const hash = window.location.hash
      const panelTarget = $(`[aria-controls='${hash.substring(1)}']`)

      if ($(hash).length) {
        const $panel = $('[aria-controls]', this)
        $panel.attr('aria-expanded', 'false')
        $panel.next().attr('aria-hidden', 'true').slideUp()
        panelTarget.attr('aria-expanded', 'true')
        $(hash).slideDown(this.animationDuration, () => {
          this.scrollTo(panelTarget)
        })
      }
    }
  }

  togglePanel () {
    const $panel = $(this)
    if ($panel.attr('aria-expanded') === 'true') {
      $panel.attr('aria-expanded', 'false')
      $panel.next().attr('aria-hidden', 'true').slideUp()
    } else {
      $panel.attr('aria-expanded', 'true')
      $panel.next().attr('aria-hidden', 'false').slideDown()
    }
  }

  scrollTo (anchor) {
    let anchorOffset = this.isMobile() ? anchor.offset().top - 60 : anchor.offset().top - 85
    $('html, body').animate({
      scrollTop: anchorOffset
    }, this.animationDuration)
  }

  isMobile () {
    return window.matchMedia('(max-width: 1023px)').matches
  }
}

window.customElements.define('flynt-accordion-vertical', AccordionVertical, { extends: 'div' })
