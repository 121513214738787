/* globals ga */
import $ from 'jquery'
import Cookies from 'js-cookie'

class BlockCookieNotice extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  bindFunctions () {
    this.acceptTracking = this.acceptTracking.bind(this)
    this.denyTracking = this.denyTracking.bind(this)
    this.resetTracking = this.resetTracking.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-accept]', this.acceptTracking)
    this.$.on('click', '[data-deny]', this.denyTracking)
    $('.button--reset-cookie').on('click', this.resetTracking)
  }

  resolveElements () {
    this.cookieName = 'acceptTracking'
    this.gaId = this.props.gaId || ''
    this.disableStr = 'ga-disable-' + this.props.gaId
    window[this.disableStr] = true
  }

  connectedCallback () {
    this.checkOptState()
  }

  checkOptState () {
    if (document.cookie.match(this.cookieName) === null) {
      this.showModal()
    }
    const getCookieValue = Cookies.get(this.cookieName) ? Cookies.get(this.cookieName) : 'false'
    if (this.gaId) {
      if (getCookieValue === 'false') {
        window[this.disableStr] = true
      } else {
        window[this.disableStr] = false
      }
    }
  }

  acceptTracking () {
    Cookies.set(this.cookieName, true, { expires: Number(this.props.expiryDays) })
    this.hideModal()
    if (this.gaId) {
      window[this.disableStr] = false
      if (ga) {
        ga('send', 'pageview')
      }
    }
  }

  denyTracking () {
    Cookies.set(this.cookieName, false, { expires: Number(this.props.expiryDays) })
    this.hideModal()
    if (this.gaId) {
      window[this.disableStr] = true
    }
  }

  hideModal () {
    this.$.removeClass('cookieNotice--isVisible')
  }

  showModal () {
    this.$.addClass('cookieNotice--isVisible')
  }

  resetTracking (e) {
    e.preventDefault()
    Cookies.remove(this.cookieName, { path: '/' })
    if (this.gaId) {
      window[this.disableStr] = false
    }
    this.showModal()
  }
}

window.customElements.define('flynt-block-cookie-notice', BlockCookieNotice, { extends: 'div' })
