import $ from 'jquery'
import throttle from 'lodash.throttle'

class NavigationMain extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.$menuWithChildren.addClass('menu-drop--hasChildren')
  }

  resolveElements () {
    this.$menuItem = $('.menu-item', this)
    this.$document = $(document)
    this.$mainHeader = $('.mainHeader')
    this.$menuWithChildren = $('.menu-drop-item:has(ul)', this).parent()
    this.$scrollTop = $('.scrollTop')
    this.$body = $('html, body')
    this.imgs = $('img')
  }

  connectedCallback () {
    const $animationSpeed = 300
    let preventActive = true
    this.$menuItem.on('mouseenter focusin', function () {
      const $item = $(this)
      this.timerOpen = setTimeout(() => {
        if ($item.find('.menu-drop-wrap').length && !$item.hasClass('menu-item--expanded')) {
          $item.addClass('menu-item--expanded')
          $item.find('.menu-drop-wrap').slideDown($animationSpeed, function () { $item.addClass('menu-item--visible') })
        }
      }, $animationSpeed)
      clearTimeout(this.timerClose)
    }).on('mouseleave focusout', function () {
      const $item = $(this)
      this.timerClose = setTimeout(() => {
        if ($item.hasClass('menu-item--expanded')) {
          $item.removeClass('menu-item--visible').delay($animationSpeed).removeClass('menu-item--expanded')
          $item.find('.menu-drop-wrap').slideUp($animationSpeed)
        }
      }, $animationSpeed)
      clearTimeout(this.timerOpen)
      preventActive = true
    })

    this.$menuItem.on('click', function (event) {
      const $item = $(this)
      if ($item.find('.menu-drop-wrap').length && preventActive && 'ontouchstart' in document.documentElement) {
        event.preventDefault()
        preventActive = false
      }
    })

    this.$document.on('scroll', throttle(() => {
      if (this.$document.scrollTop() >= 50) {
        this.$mainHeader.addClass('mainHeader-menuIsSticky')
        this.$scrollTop.addClass('visible')
      } else {
        this.$mainHeader.removeClass('mainHeader-menuIsSticky')
        this.$scrollTop.removeClass('visible')
      }
    }, 200))

    this.$scrollTop.on('click', () => {
      this.$body.animate({ scrollTop: 0 }, '300')
    })
  }
}

window.customElements.define('flynt-navigation-main', NavigationMain, {
  extends: 'nav'
})
